import axios from 'axios'

export default class HelpCenterService {
    getHelpItemsByProfileType(profileTypeId, handleResponse) {
        var endPoint = `${process.env.VUE_APP_GATEWAYENDPOINT}/help/help/get/all/for/profiletype/${profileTypeId}`;
        axios.get(endPoint)
            .then(response => handleResponse(response))
            .catch(error => {
                handleResponse(error);
            });
    }
}